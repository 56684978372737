<script lang="ts" setup>
import MSliderNavigation from "~/molecules/MSliderNavigation.vue";

import type { KeenSliderOptions } from "keen-slider";

import type { GameLastWinner } from "@/types";

const { t } = useT();
const { open } = useFunrizeModals();
const loginGuard = useLoginGuard();
const { playersActivity, fetchedPlayersActivity } = useRecentGamesWinners();
const documentVisibleState = useDocumentVisibility();
const { isSweepStakes } = useSwitchMode();

const sliderRef = ref();
const isDisabledNextMove = ref(false);

const sliderOptions: KeenSliderOptions = {
	slides: { perView: "auto", spacing: 0 },
	animationEnded(slider) {
		isDisabledNextMove.value = (slider?.track?.details?.slides?.at(-1)?.portion ?? 0) >= 0.9;
	}
};

const updateSlider = () => {
	nextTick(() => {
		sliderRef.value?.slider?.update(undefined, 0);
	});
};

const handleClickCard = (item: GameLastWinner) => {
	loginGuard({
		success: () => {
			const { slug } = item.data;
			if (!slug) {
				return;
			}

			if (item.data.gameExclusiveData?.isExclusive && !isSweepStakes.value) {
				open("LazyOModalGamePreviewExclusive", {
					gameId: item.data.id
				});

				return;
			}

			open("LazyOModalGamePreview", { game: item.data });
			dispatchGAEvent({
				event: "click_button",
				location: slug ?? "",
				button_name: "gameplay",
				form_name: "LATEST WINNERS"
			});
		},
		fail: () => open("LazyOModalSignup")
	});
};
</script>
<template>
	<div class="activity-feed">
		<div class="title">
			<NuxtIcon filled name="24/win" />
			<AText class="text-cannes" variant="tampa" as="h3" data-tid="last-winners" :modifiers="['bold']">
				{{ t("latest winners") }}
			</AText>
			<ABadge variant="info" background="var(--cebal)" autosize>
				<AText class="text-cannes" variant="tivat" :modifiers="['uppercase', 'bold']">
					{{ t("live") }}
				</AText>
			</ABadge>
		</div>
		<ClientOnly>
			<ASlider v-if="documentVisibleState === 'hidden'" ref="sliderRef" :options="sliderOptions">
				<TransitionGroup name="list" @after-enter="updateSlider">
					<div
						v-for="(item, index) in fetchedPlayersActivity"
						:key="`card-${item.type}-${item.id}`"
						class="keen-slider__slide"
					>
						<MHomeActivityFeedCard
							:winner="item"
							:data-tid="`last-winners-card-${index}`"
							@click="handleClickCard(item)"
						/>
					</div>
				</TransitionGroup>

				<template v-if="fetchedPlayersActivity.length" #dots="{ slider, activeIndex }">
					<MSliderNavigation
						location="activity-feed"
						location-tid="last-winners"
						:is-disabled-prev="activeIndex === 0"
						:is-disabled-next="isDisabledNextMove"
						@click-prev="slider?.prev()"
						@click-next="slider?.next()"
					/>
				</template>
			</ASlider>
			<ASlider v-if="documentVisibleState === 'visible'" ref="sliderRef" :options="sliderOptions">
				<TransitionGroup name="list" @after-enter="updateSlider">
					<div
						v-for="(item, index) in playersActivity"
						:key="`card-${item.type}-${item.id}-${item.login}`"
						class="keen-slider__slide"
					>
						<MHomeActivityFeedCard
							:winner="item"
							:data-tid="`last-winners-card-${index}`"
							@click="handleClickCard(item)"
						/>
					</div>
				</TransitionGroup>

				<template v-if="playersActivity.length" #dots="{ slider, activeIndex }">
					<MSliderNavigation
						location="activity-feed"
						location-tid="last-winners"
						:is-disabled-prev="activeIndex === 0"
						:is-disabled-next="isDisabledNextMove"
						@click-prev="slider?.prev()"
						@click-next="slider?.next()"
					/>
				</template>
			</ASlider>

			<template #fallback>
				<div class="skeleton-grid">
					<ASkeleton v-for="item in 8" :key="item" width="210px" height="326px" />
				</div>
			</template>
		</ClientOnly>
	</div>
</template>
<style lang="scss" scoped>
.list-move {
	transition: transform 0.5s ease;
}

.activity-feed {
	padding: gutter(3) 0;
	position: relative;

	@include media-breakpoint-down(md) {
		padding: gutter(2.5) 0;
	}

	.title {
		--a-badge-info-padding: 0 4px;

		display: flex;
		align-items: center;
		gap: gutter(1);

		.nuxt-icon {
			font-size: 24px;

			&:deep(path) {
				fill: var(--cannes);
			}

			@include media-breakpoint-down(md) {
				font-size: 20px;
			}
		}
	}

	&:deep(.keen-slider) {
		display: flex;
		position: relative;
		margin-top: gutter(3);

		@include media-breakpoint-down(md) {
			margin-top: gutter(1.5);
		}
	}

	.keen-slider__slide {
		flex-shrink: 0;
		width: calc(186px + 24px);
		padding-right: gutter(3);

		@include media-breakpoint-down(md) {
			width: calc(160px + 8px);
			padding-right: gutter(1);
		}
	}

	.navigation-holder {
		position: absolute;
		right: 0;
		top: 24px;
	}

	.skeleton-grid {
		overflow: hidden;
		display: flex;
		gap: 24px;
		margin-top: 18px;

		> .skeleton {
			flex-shrink: 0;
		}
	}
}
</style>
